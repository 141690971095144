<template>
  <v-container fluid grid-list-md class="pa-4">
    <v-btn class="ml-0 mb-2" dark color="app-blue" :to="'/missions'">
      <v-icon left class="mr-1">mdi-chevron-left</v-icon>
      {{ $t('missions.backToMissions') }}
    </v-btn>
    <v-row v-if="$vuetify.breakpoint.smAndDown" class="hidden-md-and-up mb-3">
      <v-col cols="12">
        <user-profile-card></user-profile-card>
        <div class="text-center mt-3 mb-2">
          <support-us></support-us>
        </div>
        <div class="text-center">
          <feedback></feedback>
        </div>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col md="6" sm="12" cols="12">
        <member-mission
          v-if="isGroupMission"
          :mission="currentMission"
          :isDetailPage="true"
          :showInfo="showInfo"
        ></member-mission>
        <dashboard-mission-card
          v-else
          :mission="currentMission"
          :copy="copy"
          :update="update"
          :del="del"
          :isDetailPage="true"
        ></dashboard-mission-card>
        <div v-if="canCompleteMission" class="mt-2">
          <v-btn dark color="app-blue" block @click="completeMission()" tile>
            {{
              $t('missions.completeMissionBtn', {
                gold: currentMission.gold
              })
            }}
          </v-btn>
        </div>
      </v-col>
      <v-col md="6" sm="12" cols="12">
        <group-mission-tasks
          v-if="isGroupMission"
          :title="$t('tasks.tasks')"
          :mission="currentMission"
        ></group-mission-tasks>
        <mission-tasks
          v-else
          :mission="currentMission"
          :title="$t('tasks.tasks')"
        ></mission-tasks>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserProfileCard from '@/components/users/UserProfileCard';
import SupportUs from '@/components/shared/SupportUs';
import Feedback from '@/components/shared/Feedback';
import DashboardMissionCard from '../components/missions/DashboardMissionCard';
import MemberMission from '../components/group-members/missions/MemberMission';
import GroupMissionInfoModal from '../components/groups/missions/GroupMissionInfoModal';
import MissionTasks from '@/components/mission-tasks/MissionTasks';
import GroupMissionTasks from '../components/groups/missions/tasks/GroupMissionTasks';
import missionStatuses from '@/enums/mission.statuses';
import {
  UPDATE_CURRENT_MISSION,
  COPY_MISSION,
  DELETE_MISSION,
  GET_CURRENT_MISSION,
  COMPLETE_MISSION
} from '@/store/missions/types';
import { GET_TAGS } from '../store/tags/types';
import { UPDATE_USER_STATS } from '@/store/users/types';
import missionsMapper from '../mappers/missions.mapper';
import multiNotificationsMixin from '../mixins/multi.notifications.mixin';
import dailyCronMixin from '../mixins/daily.cron.mixin';

const GROUP_MISSION_INFO_MODAL_CONFIG = {
  width: '400px'
};

export default {
  name: 'mission-detail',
  components: {
    UserProfileCard,
    SupportUs,
    Feedback,
    DashboardMissionCard,
    MemberMission,
    MissionTasks,
    GroupMissionTasks
  },
  mixins: [multiNotificationsMixin, dailyCronMixin],
  computed: {
    ...mapGetters(['currentMission', 'tags']),
    canCompleteMission() {
      return (
        this.currentMission.id &&
        this.currentMission.status === missionStatuses.active &&
        this.currentMission.tasks.length > 0 &&
        this.currentMission.tasks.filter(t => !t.completed).length === 0
      );
    },
    isGroupMission() {
      return this.currentMission.group && this.currentMission.group.id;
    }
  },
  data() {
    return {
      dailyCronWatcher: null
    };
  },
  async created() {
    this.watchDailyCron();
    await this.loadData();
  },
  beforeDestroy() {
    this.dailyCronWatcher();
  },
  methods: {
    loadData() {
      const promises = [
        this.$store.dispatch(GET_CURRENT_MISSION, this.$route.params.id)
      ];

      if (this.tags.length === 0) {
        promises.push(this.$store.dispatch(GET_TAGS));
      }

      return Promise.all(promises);
    },
    async copy(payload) {
      await this.$store.dispatch(COPY_MISSION, payload);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('missions.missionClonedSuccessfully')
      });
    },
    async update(payload) {
      const missionToUpdate = missionsMapper.getMissionUpdateReqBody(payload);

      await this.$store.dispatch(UPDATE_CURRENT_MISSION, missionToUpdate);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('missions.missionUpdatedSuccessfully')
      });
    },
    showInfo(mission) {
      this.$root.$modal.show(
        GroupMissionInfoModal,
        {
          mission
        },
        GROUP_MISSION_INFO_MODAL_CONFIG
      );
    },
    async del(payload) {
      await this.$store.dispatch(DELETE_MISSION, payload);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('missions.missionDeletedSuccessfully')
      });

      this.$router.push({ path: '/missions' });
    },
    async completeMission() {
      const result = await this.$store.dispatch(
        COMPLETE_MISSION,
        this.currentMission.id
      );

      await this.$store.dispatch(UPDATE_USER_STATS, result.stats);
      this.notifySuccess(result.diff, 'users.stats.success');
    },
    watchDailyCron() {
      this.dailyCronWatcher = this.$store.watch(
        (state, getters) => getters.cronData,
        async cronData => {
          if (!cronData) return;

          await this.loadData();
        }
      );
    }
  }
};
</script>
